<script setup lang="ts">
  import { useRouter } from 'vue-router'

  const router = useRouter()

  const back = () => {
    if (window.history.state.position > 1) {
      router.go(-1)
    } else {
      router.replace({ name: 'home' })
    }
  }
</script>

<template>
  <router-link :to="{ name: 'home' }" class="cursor-pointer" @click="back">
    <slot></slot>
  </router-link>
</template>
