<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'

  const route = useRoute()

  // show filter button next to search bar on specific page
  const filterLink = computed(() => {
    switch (route.name) {
      case 'product list':
        return { name: 'product filter', query: { ...route.query } }
      case 'shop order list':
        return { name: 'shop order filter', query: { ...route.query } }
      case 'digital order list':
        return { name: 'digital order filter', query: { ...route.query } }
      case 'stock request list':
        return { name: 'stock request filter', query: { ...route.query } }
      default:
        return null
    }
  })

  const countFilters = ref(0)

  const updateCountFilters = () => {
    const queries = new URLSearchParams(location.search)
    countFilters.value = Array.from(queries.keys()).filter(
      (key) => !['cari', 'urutan', 'hlm'].includes(key),
    ).length
  }

  watch(() => route.fullPath, updateCountFilters)

  updateCountFilters()
</script>

<template>
  <app-button v-if="filterLink" :to="filterLink" class="relative">
    <fa-icon
      icon="i-fal-filter-list"
      class="block text-2xl text-white"
    ></fa-icon>

    <div
      v-if="countFilters"
      class="absolute right-0 translate-x-1/2 rounded-full bg-danger px-1.5 text-white text-3xs -top-1"
    >
      {{ countFilters }}
    </div>
  </app-button>
</template>
