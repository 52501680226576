<script setup lang="ts"></script>

<template>
  <span class="strike">
    <slot></slot>
  </span>
</template>

<style lang="postcss" scoped>
  .strike {
    @apply relative inline-block;
  }
  span::before {
    @apply content-empty absolute inset-0;
    background: linear-gradient(
      to left top,
      transparent 47%,
      currentColor 50%,
      transparent 53%
    );
  }
</style>
