import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { usePageStore } from '@/stores/page'
import LayoutApp from '@/views/app/layout-app.vue'
import LayoutPrint from '@/views/app/layout-print.vue'
import authRoutes from './auth'
import changelogRoute from './changelog'
import checkoutRoute from './checkout'
import customerRoutes from './customer'
import customerInviteRoutes from './customer-invite'
import digitalOrderRoutes from './digital-order'
import { error404, error503 } from './error'
import homeRoute from './home'
import productRoutes from './product'
import profitBalanceRoutes from './profit-balance'
import searchRoute from './search'
import settingAccountRoute from './setting-account'
import settingStoreRoutes from './setting-store'
import shopOrderRoutes from './shop-order'
import stockRequestRoutes from './stock-request'

function layoutApp(path: string, children: RouteRecordRaw[]): RouteRecordRaw {
  return {
    path,
    children,
    component: LayoutApp,
  }
}
function layoutPrint(path: string, children: RouteRecordRaw[]): RouteRecordRaw {
  return {
    path,
    children,
    component: LayoutPrint,
  }
}

const routes: RouteRecordRaw[] = [
  layoutApp('', [homeRoute]),
  layoutApp('/cari', [searchRoute]),
  layoutApp('/customer', customerRoutes),
  layoutApp('/produk', productRoutes),
  layoutApp('/minta-stok', stockRequestRoutes),
  layoutApp('/penjualan/digital', digitalOrderRoutes),
  layoutApp('/penjualan', shopOrderRoutes),
  layoutApp('/pengaturan/toko', settingStoreRoutes),
  layoutApp('/pengaturan/akun', [settingAccountRoute]),
  layoutApp('/riwayat-perubahan', [changelogRoute]),
  layoutApp('/customer/undang', customerInviteRoutes),
  layoutApp('/checkout', [checkoutRoute]),
  layoutApp('/saldo-profit', [profitBalanceRoutes]),
  authRoutes,
  error503,
  error404,
  layoutPrint('/nota', [
    {
      path: 'penjualan/:id',
      name: 'shop order receipt',
      component: () => import('@/views/shop-order/show/page-receipt.vue'),
      props: true,
      meta: { title: 'Penjualan' },
    },
  ]),
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const page = usePageStore()

  page.resetErrorState()
  if (!to.meta.title) {
    throw new Error('Page must have a title.')
  }

  page.setTitle(to.meta.title)

  next()
})

export default router

/**
 * Make sure all routes has meta with these attributes.
 */
declare module 'vue-router' {
  interface RouteMeta {
    title: string
  }
}
