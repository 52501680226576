<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import { useAppLogout } from '@/models/app'
  import { usePartnerStore } from '@/stores/partner'

  const partner = usePartnerStore()
  const { logout } = useAppLogout()

  const isOpen = ref(false)
  const version = __APP_VERSION__

  // auto close on click
  onMounted(() => {
    document.querySelectorAll('#navbar-bottom .menu a').forEach((link) => {
      link.addEventListener('click', () => {
        isOpen.value = false
      })
    })
  })

  // Whatsapp
  const whatsappLink = computed(() => {
    const baseUrl = 'https://api.whatsapp.com/send'
    const phone = partner.whatsapp
    const query: Record<string, string> = {
      phone: phone ?? '',
    }
    const queryString = new URLSearchParams(query).toString()
    return baseUrl + '?' + queryString
  })
</script>

<template>
  <div
    id="navbar-bottom"
    class="fixed inset-x-0 z-20 transition-all duration-250 ease"
    :class="{ '-bottom-45': !isOpen, 'bottom-0': isOpen }"
  >
    <div
      v-if="isOpen"
      class="fixed inset-0 bg-black bg-opacity-60"
      @click="isOpen = false"
    ></div>
    <div class="relative z-10 text-center container -mb-3">
      <app-button
        class="mx-auto h-6 w-6 flex items-center justify-center rounded-full bg-primary-500 text-white text-3xs"
        @click="isOpen = !isOpen"
      >
        <fa-icon v-if="isOpen" icon="i-far-chevron-down"></fa-icon>
        <fa-icon v-else icon="i-far-chevron-up"></fa-icon>
      </app-button>
    </div>
    <div class="relative border rounded-t-lg bg-white container">
      <div id="navbar-bottom-slot"></div>
      <div class="menu pt-3">
        <div class="grid grid-cols-5 mb-2 h-12">
          <router-link :to="{ name: 'home' }" class="link">
            <fa-icon icon="i-fas-house" class="icon"></fa-icon>
            <div class="menu-label">Beranda</div>
          </router-link>
          <router-link :to="{ name: 'product list' }" class="link">
            <fa-icon icon="i-fas-boxes-stacked" class="icon"></fa-icon>
            <div class="menu-label">Produk</div>
          </router-link>
          <router-link :to="{ name: 'checkout' }" class="link">
            <fa-icon icon="i-fas-inbox-in" class="icon"></fa-icon>
            <div class="menu-label">Minta Stok</div>
          </router-link>
          <router-link :to="{ name: 'shop order list' }" class="link">
            <fa-icon icon="i-fas-inbox-out" class="icon"></fa-icon>
            <div class="menu-label">Penjualan</div>
          </router-link>
          <router-link :to="{ name: 'digital order list' }" class="link">
            <fa-icon icon="i-fas-mobile-signal" class="icon"></fa-icon>
            <div class="menu-label">P. Pulsa</div>
          </router-link>
        </div>
        <div class="grid grid-cols-5 h-16">
          <router-link :to="{ name: 'stock request list' }" class="link">
            <fa-icon icon="i-fas-inbox-in" class="icon"></fa-icon>
            <div class="menu-label">
              Permintaan
              <br />
              Stok
            </div>
          </router-link>
          <router-link :to="{ name: 'customer invite' }" class="link">
            <fa-icon icon="i-fas-user-plus" class="icon"></fa-icon>
            <div class="menu-label">
              Undang
              <br />
              Customer
            </div>
          </router-link>
          <router-link :to="{ name: 'customer list' }" class="link">
            <fa-icon icon="i-fas-users" class="icon"></fa-icon>
            <div class="menu-label">
              Customer
              <br />
              &nbsp;
            </div>
          </router-link>
          <router-link :to="{ name: 'setting account' }" class="link">
            <fa-icon icon="i-fas-user" class="icon"></fa-icon>
            <div class="menu-label">
              Pengaturan
              <br />
              Akun
            </div>
          </router-link>
          <router-link :to="{ name: 'setting store' }" class="link">
            <fa-icon icon="i-fas-store" class="icon"></fa-icon>
            <div class="menu-label">
              Pengaturan
              <br />
              Toko
            </div>
          </router-link>
        </div>
        <div class="grid grid-cols-5 h-16">
          <a :href="whatsappLink" target="_blank" class="link">
            <fa-icon icon="i-fab-whatsapp" class="icon"></fa-icon>
            <div class="menu-label">
              Hubungi
              <br />
              MS Shop
            </div>
          </a>
          <router-link :to="{ name: 'changelogs' }" class="link">
            <fa-icon icon="i-fas-timeline-arrow" class="icon"></fa-icon>
            <div class="menu-label">
              Riwayat
              <br />
              Perubahan
            </div>
          </router-link>
          <a
            href="https://play.google.com/store/apps/details?id=id.co.msshop"
            target="_blank"
            class="link"
          >
            <fa-icon icon="i-fab-google-play" class="icon"></fa-icon>
            <div class="menu-label">
              Play
              <br />
              Store
            </div>
          </a>
          <router-link :to="{ name: 'login' }" class="link" @click="logout()">
            <fa-icon
              icon="i-fas-arrow-right-from-bracket"
              class="icon"
            ></fa-icon>
            <div class="menu-label">
              Logout
              <br />
              &nbsp;
            </div>
          </router-link>
          <!-- <router-link :to="{name: 'home'}" class="link">
              <fa-icon icon="i-fas-download" class="icon"></fa-icon>
              <div class="menu-label">
                Update<br>Aplikasi
              </div>
            </router-link> -->
          <!-- <router-link :to="{name: 'home'}" class="link">
              <fa-icon icon="i-fas-message-exclamation" class="icon"></fa-icon>
              <div class="menu-label">
                Laporkan<br>Kendala
              </div>
            </router-link> -->
          <!-- <router-link :to="{ name: 'cart' }" class="link">
            <div class="relative">
              <div class="leading-0">
                <fa-icon icon="i-fas-cart-shopping" class="icon"></fa-icon>
              </div>
              <div
                v-if="cart.itemsCount"
                class="bg-danger text-3xs absolute -top-1 right-0 translate-x-1/2 rounded-full px-1.5 text-white"
              >
                {{ fmt.number(cart.itemsCount) }}
              </div>
            </div>
            <div class="menu-label">Keranjang</div>
          </router-link> -->
        </div>
        <div class="py-4 text-center text-xs">
          v{{ version }} | &copy;2019-{{ new Date().getFullYear() }} Toko Sejati
          Sugiarto
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .link {
    @apply flex flex-col items-center justify-center rounded text-center hover:bg-gray-100;
  }
  .router-link-active {
    @apply text-primary;
  }
  .icon {
    @apply my-1 text-lg;
  }
  .menu {
    @apply text-gray-500;
  }
  .menu-label {
    @apply text-2xs;
  }
</style>
