import * as Sentry from '@sentry/vue'
import { type App } from 'vue'
import { type Router } from 'vue-router'

export default {
  install(app: App<Element>, router: Router) {
    // dont run in development
    if (import.meta.env.DEV) {
      return
    }

    Sentry.init({
      app,
      dsn: 'https://29697dcee61443db84cf1729d20b3291@o4504999838941184.ingest.sentry.io/4504999925252096',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['api.msshop.co.id', /^\//],
        }),
      ],
      release: 'msshop-partner@' + __APP_VERSION__,
      ignoreErrors: [],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.001,
    })
  },
}
