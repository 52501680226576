import { defineStore } from 'pinia'
import { type CartItem } from '@/models/cart'

interface State {
  data: CartItem[]
  updatingId: number[]
}

export const useCartStore = defineStore('Cart', {
  state: (): State => ({
    data: [],
    updatingId: [],
  }),
  getters: {
    // get cart by partner product id
    find: (state) => (partnerProductId: number) => {
      return state.data.find(
        (item) => item.partner_product_id === partnerProductId,
      )
    },
    // get total qty of the cart
    itemsCount: (state) => {
      return state.data.reduce((total, item) => {
        return total + item.qty
      }, 0)
    },
    selectedAll: (state) => {
      return state.data.every((item) => item.selected)
    },
    selectedSome: (state) => {
      return (
        state.data.some((item) => item.selected) &&
        !state.data.every((item) => item.selected)
      )
    },
  },
  actions: {
    setData(data: CartItem[]) {
      this.data = data
    },
    updateData(partnerProductId: number, qty: number) {
      // update qty to 0 = remove from cart
      if (qty === 0) {
        this.removeData(partnerProductId)
        return
      }

      // find index of product in cart
      const index = this.data.findIndex((item) => {
        return item.partner_product_id === partnerProductId
      })

      // product already in cart, update qty
      if (index >= 0) {
        this.data[index].qty = qty
        return
      }

      // product not in cart, insert new data
      this.insertData(partnerProductId, qty)
    },
    insertData(partnerProductId: number, qty: number) {
      this.data.push({
        partner_product_id: partnerProductId,
        qty,
        selected: true,
      })
    },
    removeData(partnerProductId: number) {
      this.data = this.data.filter(
        (item) => item.partner_product_id !== partnerProductId,
      )
    },
    pushLoading(partnerProductId: number) {
      this.updatingId.push(partnerProductId)
    },
    popLoading(partnerProductId: number) {
      this.updatingId = this.updatingId.filter((id) => id !== partnerProductId)
    },
    selectAll() {
      this.data.forEach((item) => (item.selected = true))
    },
    unselectAll() {
      this.data.forEach((item) => (item.selected = false))
    },
    selectOne(partnerProductId: number) {
      const findCartItem = this.data.find(
        (item) => item.partner_product_id === partnerProductId,
      )
      if (findCartItem) {
        findCartItem.selected = true
      }
    },
    unselectOne(partnerProductId: number) {
      const findCartItem = this.data.find(
        (item) => item.partner_product_id === partnerProductId,
      )
      if (findCartItem) {
        findCartItem.selected = false
      }
    },
    isSelected(partnerProductId: number) {
      return this.find(partnerProductId)?.selected ?? false
    },
  },
})
