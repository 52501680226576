import { format } from 'date-fns'
import { id } from 'date-fns/locale'

export default {
  /**
   * Format date with given string format.
   * See https://date-fns.org/docs/format for accepted tokens
   */
  date: (dt: string | number | Date, fmt: string) => {
    return format(new Date(dt), fmt, { locale: id })
  },
}
