export default [
  {
    path: '',
    name: 'stock request list',
    component: () => import('@/views/stock-request/list/page-list.vue'),
    meta: { title: 'Daftar Minta Stock' },
  },
  {
    path: 'filter',
    name: 'stock request filter',
    component: () =>
      import('@/views/stock-request/list-filter/page-list-filter.vue'),
    meta: { title: 'Filter Minta Stock' },
  },
  {
    path: ':id',
    name: 'stock request detail',
    component: () => import('@/views/stock-request/show/page-show.vue'),
    props: true,
    meta: { title: 'Minta Stock' },
  },
]
