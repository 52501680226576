export default {
  path: '/',
  component: () => import('@/views/auth/layout-auth.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/page-login.vue'),
      meta: { title: 'Login' },
    },
    {
      path: '/aktivasi',
      name: 'activate account',
      component: () => import('@/views/auth/activation/page-activation.vue'),
      meta: { title: 'Aktivasi Akun Mitra' },
    },
    {
      path: '/lupa-password',
      name: 'forget password',
      component: () =>
        import('@/views/auth/forget-password/page-forget-password.vue'),
      meta: { title: 'Lupa Password' },
    },
  ],
}
