import { defineStore } from 'pinia'

interface State {
  id: number
  name: string
  address: string
  whatsapp: string | null
  referral_code: string
}

export const usePartnerStore = defineStore('Partner', {
  state: (): State => ({
    id: 0,
    name: '',
    address: '',
    referral_code: '',
    whatsapp: null,
  }),
  actions: {
    setPartner(partner: State) {
      this.id = partner.id
      this.name = partner.name
      this.address = partner.address
      this.whatsapp = partner.whatsapp
      this.referral_code = partner.referral_code
    },
    setWhatsapp(whatsapp: string) {
      this.whatsapp = whatsapp
    },
    resetPartner() {
      this.$reset()
    },
  },
})
