import { isAxiosError } from 'axios'
import { ref } from 'vue'
import { useApi } from '@/functions/api'
import { useAuthStore } from '@/stores/auth'
import { usePartnerStore } from '@/stores/partner'
import { useCartGet } from './cart'

type Address = {
  id: number
  label: string
  address: string
}

type Partner = {
  id: number
  name: string
  referral_code: string
  business_name: string
  whatsapp: string
  address: string
  updated_at: string
  created_at: string
  deleted_at: string | null
}

type User = {
  id: number
  name: string
  email: string
  phone: string
  role: string
}

type Withdraw = {
  id: number
  admin_id: number
  requested_by_id: string
  status: string
  value: number
  created_at: string
  updated_at: string
}

type SystemSetting = {
  banner_auto_slider_duration: number
  complain_period: number
  phone_whatsapp: string
}

type ResponseInit = {
  addresses: Address[]
  partner: Partner
  user: User
  system_setting: SystemSetting
}

type ResponseCountShopOrder = {
  count_waiting_payment: number
  count_new: number
  count_processing: number
  count_cancel_request: number
  count_complained: number
}
type ResponseCountDigitalOrder = {
  count_waiting_payment: number
  count_pending: number
  count_failed: number
  count_success: number
  count_canceled: number
}

type ResponseCountStockRequest = {
  count_new: number
  count_delivering: number
  count_rejected: number
  count_completed: number
}

type ResponeBalanceAndWithdraw = {
  balance: number
  withdraw: Withdraw
}

export function useAppInit() {
  const auth = useAuthStore()
  const partner = usePartnerStore()

  const { getCart } = useCartGet()

  const api = useApi()

  const loading = ref(false)

  const getData = async () => {
    loading.value = true
    try {
      const response = await api.GET<ResponseInit>('partner/app')

      auth.setUser(response.user)
      partner.setPartner(response.partner)
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 403) {
        const { logout } = useAppLogout()
        logout()
      }
    } finally {
      loading.value = false
    }
  }

  getData()
  getCart()

  return {
    loading,
  }
}

export function useAppBalanceAndWithdraw() {
  const api = useApi()
  const loading = ref(false)
  const submitting = ref(false)
  const errors = ref<FormError>({})
  const balance = ref(0)
  const withdraw = ref<Withdraw>()

  const getData = async () => {
    loading.value = true
    try {
      const response = await api.GET<ResponeBalanceAndWithdraw>(
        'partner/app/balance-and-withdraw',
      )
      balance.value = response.balance
      withdraw.value = response.withdraw
    } finally {
      loading.value = false
    }
  }

  const requestWithdraw = async () => {
    submitting.value = true
    try {
      const response = await api.POST<Withdraw>('partner/balance-withdraw', {
        balance: balance.value,
      })
      withdraw.value = response
    } catch (error) {
      errors.value = api.formErrors(error)
    } finally {
      submitting.value = false
    }
  }

  getData()

  return {
    loading,
    balance,
    withdraw,

    submitting,
    errors,
    requestWithdraw,
  }
}

export function useAppCountShopOrder() {
  const api = useApi()
  const loading = ref(false)

  const countWaitingPayment = ref(0)
  const countProcessing = ref(0)
  const countNew = ref(0)
  const countCancelRequest = ref(0)
  const countComplained = ref(0)

  const getData = async () => {
    loading.value = true
    try {
      const response = await api.GET<ResponseCountShopOrder>(
        'partner/app/count-shop-order',
      )
      countWaitingPayment.value = response.count_waiting_payment
      countNew.value = response.count_new
      countProcessing.value = response.count_processing
      countCancelRequest.value = response.count_cancel_request
      countComplained.value = response.count_complained
    } finally {
      loading.value = false
    }
  }

  getData()

  return {
    loading,
    countWaitingPayment,
    countNew,
    countProcessing,
    countCancelRequest,
    countComplained,
  }
}

export function useAppCountDigitalOrder() {
  const api = useApi()
  const loading = ref(false)
  const countWaitingPayment = ref(0)
  const countSuccess = ref(0)
  const countFailed = ref(0)
  const countCanceled = ref(0)
  const countPending = ref(0)

  const getData = async () => {
    loading.value = true
    try {
      const response = await api.GET<ResponseCountDigitalOrder>(
        'partner/app/count-shop-order',
      )
      countWaitingPayment.value = response.count_waiting_payment
      countSuccess.value = response.count_success
      countFailed.value = response.count_failed
      countCanceled.value = response.count_canceled
      countPending.value = response.count_pending
    } finally {
      loading.value = false
    }
  }

  getData()

  return {
    loading,
    countWaitingPayment,
    countSuccess,
    countFailed,
    countCanceled,
    countPending,
  }
}

export function useAppCountStockRequest() {
  const api = useApi()
  const loading = ref(false)

  const countNew = ref(0)
  const countDelivering = ref(0)
  const countRejected = ref(0)
  const countCompleted = ref(0)

  const getData = async () => {
    loading.value = true
    try {
      const response = await api.GET<ResponseCountStockRequest>(
        'partner/app/count-stock-request',
      )
      countNew.value = response.count_new
      countDelivering.value = response.count_delivering
      countRejected.value = response.count_rejected
      countCompleted.value = response.count_completed
    } finally {
      loading.value = false
    }
  }

  getData()

  return {
    loading,
    countNew,
    countDelivering,
    countRejected,
    countCompleted,
  }
}

export function useAppLogout() {
  const auth = useAuthStore()
  const api = useApi()

  const logout = async () => {
    await api.POST('logout')
    auth.$reset()
  }

  return {
    logout,
  }
}

export function useAppConfirmPassword() {
  const api = useApi()
  const password = ref('')

  const confirmPassword = () => {
    return api.POST('user/confirm-password', { password: password.value })
  }

  return {
    password,
    confirmPassword,
  }
}
