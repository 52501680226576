<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt, randomId } from '@/functions'

  const props = defineProps<{
    id?: string
    type?: string
    label?: string
    placeholder?: string
    required?: boolean
    error?: string[]
    disabled?: boolean
  }>()

  const modelValue = defineModel<string>()

  const formattedDate = computed(() => {
    if (modelValue.value) {
      return fmt.date(modelValue.value, 'd MMM yyyy')
    }

    return ''
  })

  const openDatePicker = () => {
    const el = document.getElementById(inputId.value)
    if (el && el instanceof HTMLInputElement) {
      el.showPicker()
    }
  }

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? 'text-' + defaultId)
</script>

<template>
  <app-input :id="inputId" :label="label" :error="error">
    <div class="relative">
      <input
        :id="inputId"
        v-model="modelValue"
        type="date"
        class="absolute inset-0 -z-1"
        :disabled="disabled"
        :required="required"
        tabindex="9999"
      />
      <input
        type="text"
        :value="formattedDate"
        readonly
        :placeholder="placeholder"
        class="form-input"
        :class="{ 'has-error': error }"
        @focus="openDatePicker"
        @click="openDatePicker"
      />
      <div
        class="pointer-events-none absolute inset-y-0 right-2 flex items-center text-sm leading-0"
      >
        <fa-icon icon="i-far-calendar"></fa-icon>
      </div>
    </div>
  </app-input>
</template>
