<script setup lang="ts">
  import { useMounted } from '@vueuse/core'

  const isMounted = useMounted()
</script>

<template>
  <div>
    <teleport v-if="isMounted" to="#navbar-bottom-slot">
      <div class="border-b px-4 py-2 -mx-4">
        <slot></slot>
      </div>
    </teleport>
  </div>
</template>
