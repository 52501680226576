<script setup lang="ts">
  import TheNavbarBottom from '@/components/the-navbar-bottom.vue'
  import TheNavbarTop from '@/components/the-navbar-top.vue'
  import { useAppInit } from '@/models/app'
  import { useAuthStore } from '@/stores/auth'

  const auth = useAuthStore()

  useAppInit()
</script>

<template>
  <div>
    <template v-if="auth.id">
      <the-navbar-top></the-navbar-top>

      <the-navbar-bottom></the-navbar-bottom>

      <div class="mb-40 mt-20 container">
        <router-view></router-view>
      </div>
    </template>
  </div>
</template>
