import { defineStore } from 'pinia'

const APP_TITLE = 'Mitra MS Shop'

export const usePageStore = defineStore('Page', {
  state: () => ({
    title: '',
    showError404: false,
  }),
  actions: {
    setTitle(title: string) {
      this.title = title
      document.title = `${title} | ${APP_TITLE}`
    },
    resetErrorState() {
      this.showError404 = false
    },
    showNotFoundError() {
      this.showError404 = true
    },
  },
})
