<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { debounce } from '@/functions'

  const route = useRoute()
  const router = useRouter()
  const searchtext = ref<string>(route.query.cari?.toString() ?? '')

  const listPages = [
    'product list',
    'shop order list',
    'stock request list',
    'customer list',
  ]

  const placeholder = computed(() => {
    switch (route.name) {
      case 'product list':
        return 'Cari nama, sku, kategori produk...'
      case 'shop order list':
        return 'Cari nomor transaksi, nama pembeli, produk...'
      case 'stock request list':
        return 'Cari nama, sku, kategori produk...'
      case 'customer list':
        return 'Cari nama, telepon, email, alamat pelanggan...'
      default:
        return 'Cari produk, no transaksi, pelanggan...'
    }
  })

  const submitSearch = () => {
    // if current route is one of these, just append ?cari to the route
    if (listPages.includes(route.name?.toString() ?? '')) {
      router.push({
        query: {
          ...route.query,
          cari: searchtext.value ? searchtext.value : undefined,
        },
      })
      return
    }

    // otherwise, open search page
    router.push({
      name: 'search',
      query: {
        cari: searchtext.value ? searchtext.value : undefined,
        tipe: 'produk',
      },
    })
  }

  const deb = debounce(submitSearch, 500)
  watch(searchtext, (value) => {
    if (value) {
      deb()
    } else {
      router.push({
        query: {
          ...route.query,
          cari: undefined,
        },
      })
    }
  })
  watch(
    () => route.fullPath,
    () => {
      searchtext.value = route.query.cari?.toString() ?? ''
    },
  )
</script>

<template>
  <form class="relative flex-1" @submit.prevent="submitSearch">
    <div class="absolute left-3.5 top-2 z-20 text-gray-400">
      <fa-icon icon="i-far-magnifying-glass" class="text-sm"></fa-icon>
    </div>
    <app-input
      id="global-search"
      v-model="searchtext"
      type="search"
      :placeholder="placeholder"
    ></app-input>
  </form>
</template>

<style lang="postcss" scoped>
  :deep() input[type='search'] {
    @apply py-2.5 pl-10 text-xs;
  }
</style>
