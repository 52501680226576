export const error404 = {
  path: '/:pathMatch(.*)*',
  component: () => import('@/views/errors/error-404.vue'),
  meta: { title: 'Halaman Tidak Ditemukan' },
}

export const error503 = {
  path: '/maintenance',
  name: 'maintenance',
  component: () => import('@/views/errors/error-503.vue'),
  meta: { title: 'Maintenance' },
}
