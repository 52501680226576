<script setup lang="ts">
  import { type SelectHTMLAttributes, computed } from 'vue'
  import { randomId } from '@/functions'

  const props = defineProps<{
    id?: string
    modelValue: SelectHTMLAttributes['value']
    icon?: string
    disabled?: boolean
    label?: string
    required?: boolean
    error?: string[]
    placeholder?: string
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: typeof props.modelValue): void
    (e: 'change', value: typeof props.modelValue): void
  }>()

  const computedValue = computed({
    set: (value) => {
      emit('update:modelValue', value)
      emit('change', value)
    },
    get: () => props.modelValue,
  })

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? 'text-' + defaultId)
</script>

<template>
  <app-input :label="label" :error="error">
    <div class="relative flex items-center">
      <select
        :id="inputId"
        v-model="computedValue"
        :required="required"
        class="form-input cursor-pointer appearance-none pr-8"
        :class="{
          'indent-6': icon,
          'has-error': error,
        }"
        :disabled="disabled"
      >
        <option v-if="placeholder" :value="null" hidden>
          {{ placeholder }}
        </option>
        <slot></slot>
      </select>

      <div
        v-if="icon"
        class="pointer-events-none absolute left-3 z-10 text-gray-500"
      >
        <span :class="icon"></span>
      </div>
      <div class="pointer-events-none absolute right-2 z-10 text-xs">
        <fa-icon icon="i-far-chevron-down"></fa-icon>
      </div>
    </div>
  </app-input>
</template>
