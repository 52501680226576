import { debounce } from '@/functions'
import { useApi } from '@/functions/api'
import { useCartStore } from '@/stores/cart'

export type CartItem = {
  partner_product_id: number
  qty: number
  selected: boolean
}

export function useCartGet() {
  const cart = useCartStore()
  const api = useApi()

  const getCart = async () => {
    const response = await api.GET<ApiCollection<CartItem>>(
      'partner/stock-request/cart',
    )
    cart.setData(response.data)
  }

  return {
    getCart,
  }
}

export function useCartUpdate() {
  const cart = useCartStore()
  const api = useApi()

  const postUpdate = async (partnerProductId: number, qty: number) => {
    cart.pushLoading(partnerProductId)
    await api.POST('partner/stock-request/cart', {
      partner_product_id: partnerProductId,
      qty,
    })
    cart.popLoading(partnerProductId)
  }
  const deb = debounce(postUpdate, 500)

  const updateCart = (partnerProductId: number, qty: number) => {
    cart.updateData(partnerProductId, qty)
    cart.pushLoading(partnerProductId)
    deb(partnerProductId, qty)
  }

  const deleteFromCart = async (partnerProductId: number) => {
    cart.pushLoading(partnerProductId)
    await postUpdate(partnerProductId, 0)
    cart.removeData(partnerProductId)
    cart.popLoading(partnerProductId)
  }

  return {
    updateCart,
    deleteFromCart,
  }
}

export function useCartClear() {
  const cart = useCartStore()
  const api = useApi()

  const clearCart = async () => {
    await api.DELETE('partner/stock-request/cart')
    cart.unselectAll()
    cart.setData([])
  }

  return {
    clearCart,
  }
}
