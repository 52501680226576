export default [
  {
    path: '',
    name: 'customer list',
    component: () => import('@/views/customer/page-list.vue'),
    meta: { title: 'Daftar Pelanggan' },
  },
  {
    path: ':id/:name?',
    name: 'customer detail',
    component: () => import('@/views/customer/page-show.vue'),
    props: true,
    meta: { title: 'Pelanggan' },
  },
]
