<script setup lang="ts">
  import { computed } from 'vue'
  import { randomId } from '@/functions'

  const props = defineProps<{
    id?: string
    modelValue?: string
    type?: string
    label?: string
    placeholder?: string
    required?: boolean
    error?: string[]
    maxLength?: number
    rows?: number
    disabled?: boolean
    autocomplete?: string
    readonly?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
  }>()

  const value = computed({
    set: (val) => {
      emit('update:modelValue', val ?? '')
    },
    get: () => props.modelValue,
  })

  const onInput = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement
    emit('update:modelValue', eventTarget.value)
  }

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? 'text-' + defaultId)
</script>

<template>
  <div>
    <slot name="label" :input-id="inputId">
      <label v-if="label" :for="inputId" class="form-label">
        {{ label }}
      </label>
    </slot>
    <slot>
      <textarea
        v-if="type === 'textarea'"
        :id="inputId"
        class="form-input min-h-24"
        :class="{ 'has-error': error }"
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        :rows="rows ?? 3"
        :required="required"
        :maxlength="maxLength"
        :readonly="readonly"
        @input="onInput"
      ></textarea>
      <input
        v-else
        :id="inputId"
        class="form-input"
        :class="{ 'has-error': error }"
        :disabled="disabled"
        :autocomplete="autocomplete ?? 'off'"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :readonly="readonly"
        :maxlength="maxLength"
        @input="onInput"
      />
    </slot>
    <app-error-msg :message="error"></app-error-msg>
  </div>
</template>

<style lang="postcss" scoped>
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
</style>
