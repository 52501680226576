<script setup lang="ts">
  import {
    Dialog as AppDialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import { ref } from 'vue'

  defineProps<{
    title?: string
    maxWidth?: string
  }>()

  const emit = defineEmits<{
    (e: 'submit'): void
  }>()

  const isOpen = ref(false)

  const open = () => {
    isOpen.value = true
  }
  const close = () => {
    isOpen.value = false
  }

  defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <transition-root appear :show="isOpen" as="template">
      <app-dialog as="div" class="relative z-20" @close="close">
        <transition-child
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-50"></div>
        </transition-child>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="min-h-full flex items-center justify-center p-4 text-center"
          >
            <transition-child
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <dialog-panel
                class="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all space-y-6"
                :style="{ 'max-width': maxWidth ?? '28rem' }"
              >
                <dialog-title
                  v-if="title"
                  class="lg:max-w-80 max-w-64 text-lg font-medium leading-6"
                >
                  {{ title }}
                </dialog-title>

                <div class="absolute right-4 -top-2">
                  <button
                    class="text-gray-400 hover:text-gray-800"
                    @click="close"
                  >
                    <fa-icon icon="i-fas-xmark" class="icon"></fa-icon>
                  </button>
                </div>

                <form @submit.prevent="emit('submit')">
                  <div>
                    <slot></slot>
                  </div>

                  <div class="modal-footer">
                    <slot name="footer"></slot>
                  </div>
                </form>
              </dialog-panel>
            </transition-child>
          </div>
        </div>
      </app-dialog>
    </transition-root>
  </teleport>
</template>

<style lang="postcss" scoped>
  :deep() .modal-footer {
    @apply mt-6 flex items-center justify-end space-x-4;
  }
</style>
