import { type App } from 'vue'
import AppBadgeDiscount from './app-badge-discount.vue'
import AppButtonBack from './app-button-back.vue'
import AppButton from './app-button.vue'
import AppCartQty from './app-cart-qty.vue'
import AppCheckbox from './app-checkbox.vue'
import AppDatepicker from './app-datepicker.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppImg from './app-image.vue'
import AppInputPassword from './app-input-password.vue'
import AppInput from './app-input.vue'
import AppLoadingDot from './app-loading-dot.vue'
import AppLoading from './app-loading.vue'
import AppModal from './app-modal.vue'
import AppNavbarBottomSlot from './app-navbar-bottom-slot.vue'
import AppNotice from './app-notice.vue'
import AppNumeric from './app-numeric.vue'
import AppPaginationDetail from './app-pagination-detail.vue'
import AppPagination from './app-pagination.vue'
import AppPaymentMethodIcon from './app-payment-method-icon.vue'
import AppSelect from './app-select.vue'
import AppStrike from './app-strike.vue'
import AppTitle from './app-title.vue'
import FaIcon from './fa-icon.vue'

export default {
  install(app: App) {
    app.component('AppBadgeDiscount', AppBadgeDiscount)
    app.component('AppButton', AppButton)
    app.component('AppButtonBack', AppButtonBack)
    app.component('AppDatepicker', AppDatepicker)
    app.component('AppCartQty', AppCartQty)
    app.component('AppCheckbox', AppCheckbox)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppImg', AppImg)
    app.component('AppInputPassword', AppInputPassword)
    app.component('AppInput', AppInput)
    app.component('AppLoading', AppLoading)
    app.component('AppLoadingDot', AppLoadingDot)
    app.component('AppModal', AppModal)
    app.component('AppNotice', AppNotice)
    app.component('AppNumeric', AppNumeric)
    app.component('AppPagination', AppPagination)
    app.component('AppPaginationDetail', AppPaginationDetail)
    app.component('AppPaymentMethodIcon', AppPaymentMethodIcon)
    app.component('AppSelect', AppSelect)
    app.component('AppStrike', AppStrike)
    app.component('AppTitle', AppTitle)
    app.component('AppNavbarBottomSlot', AppNavbarBottomSlot)
    app.component('FaIcon', FaIcon)
  },
}

declare global {
  type AppModal = InstanceType<typeof AppModal>
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppBadgeDiscount: typeof AppBadgeDiscount
    AppButton: typeof AppButton
    AppButtonBack: typeof AppButtonBack
    AppCartQty: typeof AppCartQty
    AppCheckbox: typeof AppCheckbox
    AppDatepicker: typeof AppDatepicker
    AppErrorMsg: typeof AppErrorMsg
    AppImg: typeof AppImg
    AppInput: typeof AppInput
    AppInputPassword: typeof AppInputPassword
    AppLoading: typeof AppLoading
    AppLoadingDot: typeof AppLoadingDot
    AppModal: typeof AppModal
    AppNotice: typeof AppNotice
    AppNumeric: typeof AppNumeric
    AppPagination: typeof AppPagination
    AppPaginationDetail: typeof AppPaginationDetail
    AppPaymentMethodIcon: typeof AppPaymentMethodIcon
    AppSelect: typeof AppSelect
    AppStrike: typeof AppStrike
    AppTitle: typeof AppTitle
    AppNavbarBottomSlot: typeof AppNavbarBottomSlot
    FaIcon: typeof FaIcon
  }
}
