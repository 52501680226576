import { defineStore } from 'pinia'

interface State {
  id: number
  email: string
  name: string
  role: string
}

export const useAuthStore = defineStore('Auth', {
  state: (): State => ({
    id: 0,
    email: '',
    name: '',
    role: '',
  }),
  actions: {
    setUser(user: State) {
      this.id = user.id
      this.email = user.email
      this.name = user.name
      this.role = user.role
    },
    resetUser() {
      this.$reset()
    },
    setEmail(email: string) {
      this.email = email
    },
  },
})
