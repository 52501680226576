export default [
  {
    path: '',
    name: 'product list',
    component: () => import('@/views/product/page-list.vue'),
    meta: { title: 'Daftar Produk' },
  },
  {
    path: 'filter',
    name: 'product filter',
    component: () => import('@/views/product/page-list-filter.vue'),
    meta: { title: 'Filter Produk' },
  },
  {
    path: ':id/:name?',
    name: 'product detail',
    component: () => import('@/views/product/show/page-show.vue'),
    props: true,
    meta: { title: 'Produk' },
  },
]
