<script setup lang="ts">
  import { ref } from 'vue'

  const modelValue = defineModel({ type: String })

  defineProps<{
    label?: string
    placeholder?: string
    required?: boolean
    error?: string[]
    disabled?: boolean
  }>()

  const showPassword = ref(false)
</script>

<template>
  <div class="relative">
    <app-input
      v-model="modelValue"
      :label="label"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="placeholder"
      :disabled="disabled"
      :error="error"
      :required="required"
      autocomplete="password"
    ></app-input>
    <template v-if="modelValue">
      <label
        class="absolute right-2 top-8 select-none p-1 text-gray-400 font-bold"
      >
        <input
          v-model="showPassword"
          tabindex="99"
          type="checkbox"
          class="opacity-0"
          name="show-hide-password"
        />
        <fa-icon v-if="!showPassword" icon="i-far-eye"></fa-icon>
        <fa-icon v-else icon="i-far-eye-slash"></fa-icon>
      </label>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
  :deep() input[type='text'],
  :deep() input[type='password'] {
    padding-right: 2.5rem;
  }
</style>
