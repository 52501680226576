<script setup lang="ts">
  import { computed, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { fmt } from '@/functions'

  const route = useRoute()
  const router = useRouter()

  const props = defineProps<{
    modelValue?: Pagination
  }>()

  const page = computed({
    set: (value: number) => {
      changePage(value)
    },
    get: () => {
      return currentPage.value ? currentPage.value : 1
    },
  })

  const currentPage = computed(() => props.modelValue?.current_page ?? 0)
  const lastPage = computed(() => props.modelValue?.last_page ?? 0)
  const prevLink = computed(() => {
    if (currentPage.value <= 1) {
      return
    }

    let prevPage = undefined

    if (currentPage.value > 2) {
      prevPage = currentPage.value - 1
    }

    return { ...route, query: { ...route.query, hlm: prevPage } }
  })
  const nextLink = computed(() => {
    if (currentPage.value >= lastPage.value) {
      return
    }
    return { ...route, query: { ...route.query, hlm: page.value + 1 } }
  })

  watch(currentPage, () => {
    if (!currentPage.value) {
      return
    }
    page.value = currentPage.value
  })

  const changePage = (value: number) => {
    const newPage = value <= 1 ? undefined : value
    router.push({ ...route, query: { ...route.query, hlm: newPage } })
  }

  // const pages = computed(() => {
  //   if (lastPage.value) {
  //     return Array.from({ length: lastPage.value }, (_, i) => i + 1)
  //   }
  //   return [1]
  // })
</script>

<template>
  <template
    v-if="modelValue && lastPage && currentPage <= lastPage && lastPage > 1"
  >
    <nav
      role="navigation"
      aria-label="Pagination Navigation"
      class="my-2 flex justify-center"
    >
      <div
        class="flex items-center justify-between border rounded bg-white text-sm"
      >
        <app-button
          :disabled="!prevLink"
          :to="prevLink"
          class="px-3 pt-1 text-primary disabled:text-gray-300"
        >
          <fa-icon icon="i-fal-chevron-left"></fa-icon>
        </app-button>
        <div class="py-2">
          <div class="border-l border-r px-4">
            {{ fmt.number(currentPage) }}
            dari
            {{ fmt.number(lastPage) }}
          </div>
        </div>
        <app-button
          :disabled="!nextLink"
          :to="nextLink"
          class="px-3 pt-1 text-primary disabled:text-gray-300"
        >
          <fa-icon icon="i-fal-chevron-right"></fa-icon>
        </app-button>
      </div>
    </nav>
    <div class="text-center text-sm">
      Menampilkan
      {{ fmt.number(modelValue.from) }}-{{ fmt.number(modelValue.to) }} dari
      {{ fmt.number(modelValue.total) }}
    </div>
  </template>
</template>
