<script setup lang="ts">
  import { computed, nextTick, ref } from 'vue'
  import { useCartUpdate } from '@/models/cart'
  import { useCartStore } from '@/stores/cart'
  import AppQty from './app-qty.vue'

  const cart = useCartStore()
  const { updateCart } = useCartUpdate()

  /**
   * preventDelete will disabled the minus button when qty is 1 and set v-money
   * min to 1. You need to manually invoke the updateCart to 0, usually
   * after the user clicks delete button and confirm their action.
   */
  const props = defineProps<{
    partnerProductId: number
    preventDelete?: boolean
  }>()

  const cartItem = computed(() => {
    return cart.find(props.partnerProductId) ?? { qty: 0 }
  })

  const qty = computed({
    get: () => cartItem.value.qty,
    set: (value: number) => {
      if (cartItem.value.qty !== value) {
        updateCart(props.partnerProductId, value)
      }
    },
  })

  const refAppQty = ref<InstanceType<typeof AppQty>>()
  const onInsert = () => {
    updateCart(props.partnerProductId, 1)
    nextTick(() => {
      refAppQty.value?.focus()
    })
  }
</script>

<template>
  <div>
    <div v-if="qty > 0" class="flex items-center">
      <slot></slot>
      <app-qty
        ref="refAppQty"
        v-model="qty"
        :prevent-delete="preventDelete"
      ></app-qty>
    </div>
    <app-button
      v-else
      class="text-xs leading-4 btn-primary"
      @click="onInsert()"
    >
      <fa-icon icon="i-fas-inbox-in"></fa-icon>
      <span class="ml-2">Minta Stok</span>
    </app-button>
  </div>
</template>

<style lang="postcss" scoped>
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  button:disabled,
  input:disabled {
    @apply cursor-not-allowed;
  }
  button:disabled {
    @apply cursor-not-allowed bg-gray-100 text-gray-300;
  }
</style>
