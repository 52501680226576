<script setup lang="ts">
  const URL_ICON_BCA =
    'https://d39a3n4b569wsw.cloudfront.net/static-assets/payments/bca-48.jpg'

  const url = URL_ICON_BCA
</script>

<template>
  <img class="aspect-square bg-primary-100" :src="url" />
</template>
