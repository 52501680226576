<script setup lang="ts">
  import { Money3Component as InputMoney } from 'v-money3'
  import { computed } from 'vue'
  import { randomId } from '@/functions'

  const props = defineProps<{
    id?: string
    modelValue: number
    min?: number
    label?: string
    required?: boolean
    disabled?: boolean
    placeholder?: string
    error?: string[]
    prefix?: string
    suffix?: string
    textAlign?: 'left' | 'center' | 'right'
    allowBlank?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: number): void
  }>()

  // https://github.com/jonathanpmartins/v-money3#properties
  const options = computed(() => ({
    decimal: ',',
    thousands: '.',
    precision: 0,
    min: props.min ?? 0,
    allowBlank: !!props.allowBlank,
  }))

  const textInput = computed({
    set: (val) => {
      emit('update:modelValue', Number(val))
    },
    get: () => props.modelValue,
  })

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? 'text-' + defaultId)
</script>

<template>
  <app-input :label="label" :error="error">
    <div class="relative flex items-center">
      <input-money
        :id="inputId"
        v-model="textInput"
        v-bind="options"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="[
          'form-input',
          { 'has-error': error },
          { 'text-left': textAlign === 'left' },
          { 'text-center': textAlign === 'center' },
          { 'text-right': textAlign === 'right' },
          { '!pl-12': prefix },
          { '!pr-12': suffix },
        ]"
      ></input-money>
      <span v-if="prefix" class="prefix">
        {{ prefix }}
      </span>
      <span v-if="suffix" class="suffix">
        {{ suffix }}
      </span>
    </div>
  </app-input>
</template>

<style lang="postcss" scoped>
  .suffix,
  .prefix {
    @apply pointer-events-none absolute z-10 text-xs text-gray-500;
  }
  .suffix {
    @apply right-2;
  }
  .prefix {
    @apply left-4;
  }
</style>
