export default [
  {
    path: '',
    name: 'setting store',
    component: () => import('@/views/settings/page-setting-store.vue'),
    meta: {
      title: 'Pengaturan Toko',
    },
  },
  {
    path: 'whatsapp',
    name: 'setting store whatsapp',
    component: () => import('@/views/settings/page-edit-whatsapp.vue'),
    meta: {
      title: 'Pengaturan Toko - Whatsapp',
    },
  },
  {
    path: 'metode-pembayaran',
    name: 'setting store payment method',
    component: () => import('@/views/settings/payment-method/page-list.vue'),
    meta: {
      title: 'Pengaturan Toko - Metode Pembayaran',
    },
  },
  {
    path: 'metode-pembayaran/tambah',
    name: 'setting store payment method create',
    component: () =>
      import('@/views/settings/payment-method/page-create-edit-delete.vue'),
    meta: {
      title: 'Pengaturan Toko - Tambah Metode Pembayaran',
    },
  },
  {
    path: 'metode-pembayaran/:id/edit',
    name: 'setting store payment method edit',
    props: true,
    component: () =>
      import('@/views/settings/payment-method/page-create-edit-delete.vue'),
    meta: {
      title: 'Pengaturan Toko - Tambah Metode Pembayaran',
    },
  },
]
