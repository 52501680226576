function insertDashAfter(str: string, spaceAt: number[]) {
  spaceAt.forEach((at) => {
    str = str.slice(0, at) + '-' + str.slice(at)
  })

  return str
}

/**
 * Get location where to insert a dash.
 * From the end for easier insert.
 */
function calculateSpace(phone: string) {
  switch (phone.length) {
    case 6:
      return [3]
    case 7:
      return [3]
    case 8:
      return [5, 2]
    case 9:
      return [5, 2]
    case 10:
      return [6, 2]
    case 11:
      return [7, 4]
    case 12:
      return [8, 4]
    case 13:
      return [8, 4]
    default:
      return []
  }
}

/**
 * Format phone number for easier reading.
 *
 *  6: 012345 => 012-345
 *  7: 0123456 => 012-3456
 *  8: 01234567 => 0123-4567
 *  9: 031345678 => 031-345-678
 * 10: 0317451133 => 031-745-1133
 * 11: 08123456789 => 0812-345-6789
 * 12: 081234567890 => 0812-3456-7890
 * 13: 0812345678901 => 0812-3456-78901
 */
export default function (phone: string) {
  const spaceAt = calculateSpace(phone)
  return insertDashAfter(phone, spaceAt)
}
