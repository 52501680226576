import Copy from 'clipboard-copy'
import fnDebounce from 'debounce'
import { nanoid } from 'nanoid'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import DateFormatter from './fmt/date'
import NumberFormatter from './fmt/number'
import PhoneFormatter from './fmt/phone'
import StringFormatter from './fmt/string'

export const debounce = fnDebounce

export const fmt = {
  ...DateFormatter,
  ...NumberFormatter,
  ...StringFormatter,
  phone: PhoneFormatter,
}

export const randomId = nanoid

export const copy = (text = '') => Copy(text)

/**
 * Watch the route full path, and trigger callback when query / params change
 * but don't trigger when route name change (move away to other page).
 */
export const whenRouteChange = (callback: () => void) => {
  const route = useRoute()
  const routeName = route.name
  watch(
    () => route.fullPath,
    () => {
      if (route.name === routeName) {
        callback()
      }
    },
  )
}
