export default [
  {
    path: '',
    name: 'digital order list',
    component: () => import('@/views/digital-order/list/page-list.vue'),
    meta: { title: 'Daftar Penjualan Pulsa' },
  },
  {
    path: 'filter',
    name: 'digital order filter',
    component: () =>
      import('@/views/digital-order/list-filter/page-list-filter.vue'),
    meta: { title: 'Filter Penjualan Pulsa' },
  },
  {
    path: ':id',
    name: 'digital order detail',
    component: () => import('@/views/digital-order/show/page-show.vue'),
    props: true,
    meta: { title: 'Detail Penjualan Pulsa' },
  },
]
