<script setup lang="ts">
  import { Money3Component as InputMoney } from 'v-money3'
  import { computed } from 'vue'
  import { randomId } from '@/functions'

  /**
   * preventDelete will disabled the minus button when qty is 1 and set v-money
   * min to 1. You need to manually invoke the updateCart to 0, usually
   * after the user clicks delete button and confirm their action.
   */
  const props = defineProps<{
    modelValue: number
    preventDelete?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: number): void
  }>()

  const qty = computed({
    get: () => props.modelValue,
    set: (value: number) => {
      emit('update:modelValue', value)
    },
  })

  const inputId = 'text-' + randomId()

  // https://github.com/jonathanpmartins/v-money3#properties
  const options = computed(() => ({
    decimal: ',',
    thousands: '.',
    precision: 0,
    min: props.preventDelete ? 1 : 0,
  }))

  const focus = () => {
    const input = document.getElementById(inputId) as HTMLInputElement
    input.focus()
  }

  defineExpose({
    focus,
  })
</script>

<template>
  <div class="flex border rounded divide-x">
    <div>
      <button
        class="px-2 text-primary"
        :disabled="qty === options.min"
        @click="qty--"
      >
        -
      </button>
    </div>
    <input-money
      :id="inputId"
      v-model.number="qty"
      v-bind="options"
      class="w-12 text-center"
      :class="{ 'text-sm': qty < 1000, 'text-xs': qty >= 1000 }"
    ></input-money>
    <div>
      <button class="px-2 text-primary" @click="qty++">+</button>
    </div>
  </div>
</template>
