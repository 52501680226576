export default [
  {
    path: '',
    name: 'shop order list',
    component: () => import('@/views/shop-order/list/page-list.vue'),
    meta: { title: 'Daftar Penjualan' },
  },
  {
    path: 'filter',
    name: 'shop order filter',
    component: () =>
      import('@/views/shop-order/list-filter/page-list-filter.vue'),
    meta: { title: 'Filter Penjualan' },
  },
  {
    path: ':id',
    name: 'shop order detail',
    component: () => import('@/views/shop-order/show/page-show.vue'),
    props: true,
    meta: { title: 'Penjualan' },
  },
]
